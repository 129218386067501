import React from 'react';
import CommunityDetail from './CommunityDetail';

function Index() {
    return (
        <div>
            <CommunityDetail/>
        </div>
    )
}

export default Index
