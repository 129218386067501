import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { verifyToken } from "redux/action/Auth";

const JumpAuthorize = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchParams = useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const splitOrg = search?.toString().split("organizationId=");
  const organizationId = splitOrg.length > 0 ? splitOrg[splitOrg.length - 1] : "";

  const data = {
    bearerToken: searchParams.get("token"),
    organizationId: organizationId
  }

  useEffect(() => {
    dispatch(verifyToken(data, navigate));
  }, []);

  return (
    <div></div>
  );
};

export default JumpAuthorize;
