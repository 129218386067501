import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import request from 'utils/request';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import makeToast from 'utils/Toaster';

const StyledRange = styled.div`
  .MuiCheckbox-root {
    color: #c3724e;
  }
`;

const NotificationSettings = () => {
    const navigate = useNavigate();
    const { role } = useSelector((state) => state._auth);

    const [notifications, setNotifications] = useState([]);
    const [checked, setChecked] = useState([]);
    const [user, setUser] = useState({});

    useEffect(() => {
      request.get('/settings/getEnumNotifications')
      .then((res) => {
        if (res.data?.data) {
          if (role === "admin") {
            setNotifications(res.data?.data?.enums);
          } else if (role === "designer") {
            setNotifications(res.data?.data?.enums?.filter(x => x !== "Product Creation For Approval" && x !== "Order Creation"));
          } else {
            setNotifications(["Order Creation", "Payment Creation", "Approved Request Payment From Admin", "Rejected Request Payment From Admin"]);
          }
          setChecked(res.data?.data?.user?.notifications);
          setUser(res.data?.data?.user);
        }
      })
    }, []);

    const handleSave = () => {
      const fields = {
        notifications: checked,
        id: user?._id
      };

      request.post(role === "user" ? '/users/updateUser' : "/users/admin/updateUser", fields)
      .then(() => {
        makeToast('success', 'Success update notification setting');
      })
      .catch(() => {
        makeToast('error', 'Error when update notification setting');
      })
  };

    return (
        <div className="row">
            <div className="contactussettings">
                <div className="heading">
                    <h2>Settings - Notification</h2>
                </div>
                <div className="col-md-6">
                    <StyledRange>
                      {
                        notifications?.map((notif, index) => (
                          <div>
                            <FormControlLabel 
                              control={
                                <Checkbox
                                  key={index}
                                  checked={checked.filter(x => x === notif).length > 0}
                                  value={notif}
                                  onChange={e => {
                                    const isExist = checked.filter(x => x === e.target.value).length > 0;
                                    if (isExist) {
                                      setChecked(checked.filter(x => x !== e.target.value));
                                    } else {
                                      setChecked([...checked, e.target.value]);
                                    }
                                  }}
                                />
                              }
                              label={notif}
                            />
                          </div>
                        ))
                      }
                    </StyledRange>
                </div>
                <div className="save-btn addProduct">
                    <div className="btn btn-primary saveBtn mx-2" onClick={() => navigate(-1)}>
                      Cancel
                    </div>

                    <div className="btn btn-primary saveBtn" onClick={handleSave}>
                        Save
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationSettings;
