import request from 'utils/request';
import makeToast from 'utils/Toaster';

export const getApps = async () => {
  try {
    const res = await request.get(`/apps`);
    const { status, data } = res.data;

    if (status === 'Fail') throw res.data;
    if (status === 'Success') {
      return data;        
    }
  } catch (e) {
    makeToast('error', e.message);
  }
}