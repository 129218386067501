// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { menuItemsDesigner, menuItemsManufacturer , menuItemsLogistic } from 'menu-items/otherRoutes';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { isAuthenticated, user, role } = useSelector((state) => state._auth);
    let data =
        role === 'admin' ? menuItem : role === 'designer' ? menuItemsDesigner : role === 'manufacturer' ? menuItemsManufacturer : role === 'logistic' ? menuItemsLogistic :menuItem;
    const navItems = data.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
