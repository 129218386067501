import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from 'react-bootstrap';
import { isArrayCheck } from 'views/utilities/common';

const InvoiceDetail = ({ data, handleClose }) => {
    console.log('Order Details ===>', data);
    const createPDF = async () => {
        const pdf = new jsPDF('portrait', 'pt', 'a4');
        const data = await html2canvas(document.querySelector('#pdf'));
        const img = data.toDataURL('image/png');
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);

        pdf.save('shipping_label.pdf');
    };

    const getSubtotal = () => {
        if (data?.products?.length > 0) {
            const prod = data?.products[0];
            if (prod.responseDHL?.length > 0) {
                return prod?.responseDHL[0]?.shippingFee;
            } else {
                return 0;
            }
        }

        return 0;
    }

    const getCurrency = () => {
        if (data?.products?.length > 0) {
            const prod = data?.products[0];
            if (prod.responseDHL?.length > 0) {
                if (prod?.responseDHL[0]?.responseShipping?.totalPrice.length > 0) {
                    return prod?.responseDHL[0]?.responseShipping.totalPrice[0].priceCurrency;
                }
            }
        }

        return "";
    }

    return (
        <>
            <section className="max-w-6xl mx-auto sm:px-6 lg:px-4 py-12 ">
                <div className="shipping">
                    <div id="pdf">
                        <div
                            style={{
                                margin: '10px 0px 10px 0px',
                                padding: "20px"
                            }}
                        >
                            <h4 className="mb-3 mt-2 text-center">Invoice Details</h4>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div
                                    style={{
                                        width: '49%',
                                        border: '1px solid gray',
                                        marginBottom: '5px',
                                        padding: '5px',
                                        minHeight: "215px"
                                    }}
                                >
                                    <h6
                                        style={{
                                            marginBottom: '5px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            borderBottom: '1px solid gray'
                                        }}
                                    >
                                        Shipper Information
                                    </h6>
                                    <p
                                        style={{
                                            margin: '0px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Name : {data?.createdBy?.fullname}
                                    </p>
                                    <p
                                        style={{
                                            margin: '0px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Contact No : {data?.createdBy?.phone_number}
                                    </p>
                                    <p
                                        style={{
                                            margin: '0px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Email : {data?.createdBy?.email}
                                    </p>
                                    <p
                                        style={{
                                            margin: '0px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Address : {
                                            data?.billingAddress?.state +
                                            ',' +
                                            data?.billingAddress?.address
                                        }
                                    </p>
                                    <p
                                        style={{
                                            margin: '0px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Country : {data?.billingAddress?.country}
                                    </p>
                                    <p
                                        style={{
                                            margin: '0px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Postal Code : {data?.billingAddress?.poBoxNo}
                                    </p>
                                </div>
                                <div
                                    style={{
                                        width: '49%',
                                        border: '1px solid gray',
                                        marginBottom: '5px',
                                        padding: '5px',
                                        minHeight: "215px"
                                    }}
                                >
                                    <h6
                                        style={{
                                            marginBottom: '5px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            borderBottom: '1px solid gray'
                                        }}
                                    >
                                        Shipping Information
                                    </h6>
                                    <p
                                        style={{
                                            margin: '0px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Name : {data?.products[0]?.manufacturer?.fullname}
                                    </p>
                                    <p
                                        style={{
                                            margin: '0px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Contact No : {data?.products[0]?.manufacturer?.phone_number}
                                    </p>
                                    <p
                                        style={{
                                            margin: '0px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Email : {data?.products[0]?.manufacturer?.email}
                                    </p>
                                    <p
                                        style={{
                                            margin: '0px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Address : {
                                            data?.products[0]?.manufacturer?.myAddresses[0]?.country +
                                            ',' +
                                            data?.products[0]?.manufacturer?.myAddresses[0]?.state +
                                            ',' +
                                            data?.products[0]?.manufacturer?.myAddresses[0]?.city +
                                            ',' +
                                            data?.products[0]?.manufacturer?.address
                                        }
                                    </p>
                                    <p
                                        style={{
                                            margin: '0px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Country : {data?.products[0]?.manufacturer?.country}
                                    </p>
                                    <p
                                        style={{
                                            margin: '0px',
                                            paddingLeft: '2px',
                                            fontWeight: 'bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Postal Code : {data?.products[0]?.manufacturer?.postalCode}
                                    </p>
                                </div>
                            </div>
                            <table 
                                style={{ 
                                    border: '1px solid gray', 
                                    marginTop: "15px", 
                                    marginBottom: "15px", 
                                    width: "100%" 
                                }}
                            >
                                <tr style={{ border: '1px solid gray' }}>
                                    {/* <th style={{ border: '1px solid gray', padding: '10px' }}>Order ID</th> */}
                                    <th style={{ border: '1px solid gray', padding: '10px' }}>No. of Packages</th>
                                    <th style={{ border: '1px solid gray', padding: '10px' }}>Product</th>
                                    <th style={{ border: '1px solid gray', padding: '10px' }}>Quantity</th>
                                    <th style={{ border: '1px solid gray', padding: '10px' }}>Size(cm)</th>
                                    <th style={{ border: '1px solid gray', padding: '10px' }}>Weight (kg)</th>
                                    <th style={{ border: '1px solid gray', padding: '10px' }}>Shipping</th>
                                    <th style={{ border: '1px solid gray', padding: '10px' }}>Shipping Fee</th>
                                </tr>
                                {isArrayCheck(data?.products) &&
                                    data?.products?.map((prod, index) => (
                                        <tr style={{ border: '1px solid gray' }}>
                                            {/* <td style={{ border: '1px solid gray', padding: '10px' }}>{data?._id?.substring(0, 10)}</td> */}
                                            <td style={{ border: '1px solid gray', padding: '10px' }}>{index + 1}</td>
                                            <td style={{ border: '1px solid gray', padding: '10px' }}>{prod?.productId?.name}</td>
                                            <td style={{ border: '1px solid gray', padding: '10px' }}>{prod?.quantity}</td>
                                            <td style={{ border: '1px solid gray', padding: '10px' }}>
                                                {prod?.productId?.length + ',' + prod?.productId?.breadth + ',' + prod?.productId?.height}
                                            </td>
                                            <td style={{ border: '1px solid gray', padding: '10px' }}>{prod?.productId?.weight}</td>
                                            <td style={{ border: '1px solid gray', padding: '10px' }}>
                                                {isArrayCheck(prod?.responseDHL) && prod?.responseDHL[0]?.responseShipping?.productName}
                                            </td>
                                            <td style={{ border: '1px solid gray', padding: '10px' }}>
                                                {isArrayCheck(prod?.responseDHL) && prod?.responseDHL[0]?.shippingFee}
                                            </td>
                                        </tr>
                                    ))}

                                <tr style={{ border: '1px solid gray' }}>
                                    <td colSpan={5} style={{ border: '1px solid gray', padding: '10px' }}></td>
                                    <td style={{ border: '1px solid gray', padding: '10px', fontWeight: "bold" }}>
                                        Subtotal
                                    </td>
                                    <td style={{ border: '1px solid gray', padding: '10px' }}>
                                        {getSubtotal()}
                                    </td>
                                </tr>
                                <tr style={{ border: '1px solid gray' }}>
                                    <td colSpan={5} style={{ border: '1px solid gray', padding: '10px' }}></td>
                                    <td style={{ border: '1px solid gray', padding: '10px', fontWeight: "bold" }}>
                                        Total
                                    </td>
                                    <td style={{ border: '1px solid gray', padding: '10px' }}>
                                        {getSubtotal()}
                                    </td>
                                </tr>
                                <tr style={{ border: '1px solid gray' }}>
                                    <td colSpan={5} style={{ border: '1px solid gray', padding: '10px' }}></td>
                                    <td style={{ border: '1px solid gray', padding: '10px', fontWeight: "bold" }}>
                                        Currency Code
                                    </td>
                                    <td style={{ border: '1px solid gray', padding: '10px' }}>
                                        {getCurrency()}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button
                            onClick={handleClose}
                            className="btn text-white px-5 mx-2"
                            style={{ backgroundColor: '#8c5d2f' }}
                            type="button"
                        >
                            Cancel
                        </button>

                        <button onClick={createPDF} className="btn text-white px-5" style={{ backgroundColor: '#8c5d2f' }} type="button">
                            Download
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default InvoiceDetail;
