import React from 'react'

function PromotionSettings() {
  return (
    <div>
      <div className="list-orders">
         <div className="order-tracking">
      <div className="heading d-flex justify-content-between align-item-center">
        <h2>Promotion Settings</h2>
        </div>
        </div>
      </div>
    </div>
  )
}

export default PromotionSettings